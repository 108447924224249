import React from "react"
import EntryBadgesV2 from "./badgesV2";
import License from "./licenses";
import Illustration from "../../../../assets/img/global/cta_business_footer.svg"
import Illustration2 from "../../../../assets/img/global/cta_business_footer2.png"

import { Img } from "react-image";
const CTABusiness = props => {
  return (
    <div className="kuda-section">
      <div className="kuda-entry--inner kuda-cta--section kuda-cta-dark-section-demo flex justify-between align-center py-lg-0">
        <div className="kuda-section--50">
          <div className="kuda-main--wrap biz pr-lg-5 mr-lg-5">
            <h1 className="kuda-section--heading text-xlbold color-white title-bottom--spacing">
              Move your business forward with softPOS

            </h1>
            <p className="color-white title-bottom--spacing">
              Business can find you anywhere. softPOS from Kuda helps you receive payments on the move with any smartphone connected to the internet.
            </p>
            <License className="mb-3" />

            <EntryBadgesV2 isAmbassador ambassadorUrl={"https://kudabusiness.onelink.me/epwz/jceed731/"} />
          </div>
        </div>

        <div className="kuda-section--50">
          <div className="cta-business-illustration biz-float--bottom">
            <Img src={Illustration} />
          </div>
        </div>

        <div className="cta-business-illustration biz-float--right">
          <Img src={Illustration2} />
        </div>
      </div>
    </div>
  )
}

export default React.memo(CTABusiness)
