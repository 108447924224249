import React from "react"
import BusinessHardPOS from "../../../components/body/pages/en-ng/business/HardPOS"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const HardPOS = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/pos"}
      title="Kuda POS | Boost Your Business Payments | Kuda Business"
      description="Get a Kuda POS machine so you can see all your POS transactions in real-time, receive card payments, transfers and USSD payment and pay for it with flexible payment options"
    />
    <BusinessHardPOS />
  </Layout>
)

export default HardPOS
